/* General styles - fonts etc */
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,600;0,700;1,500&display=swap');

/* Define key colours */
:root {
    --red: rgb(248, 84, 84);

    --bgWhite: #fff;

    --darkBlue: #311669;
    --darkBlueTrans: rgba(49,22,105,0.6);
    --midBlue: #5DB5F4;
    --brightBlue: #2EB9FF;
    --fadedBlueBorder: #5C4292;
    --fadedBlueBg: #E8E2F6;
    --lghtBorder: #E5D8FF;

    --whiteTxt: #fff;
    --fadedWhiteTxt: #D3C0FB;
    --fadedBlueTxt: #E1D2FF;
    --blueTxt: #260F53;

    --whiteBorderFaded: rgba(255,255,255,0.25);
}

/* Generics */
body {
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    line-height: 1.7em;
    color: var(--blueTxt);
    font-weight: 300;
    background: var(--bgWhite);
    margin: 0;
}

h1, h2, h3, h4 {
    font-family: "PredigeRoundedBold", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 42px;
    line-height: 1.1em;
}
h4 {
    font-size: 18px;
}
h5 {
    font-size: 16px;
    font-weight: 700;
}
h1, h2, h3 {
    font-family: "PredigeRoundedBold", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 42px;
    line-height: 1.1em;
}
p {
    margin: 0 0 18px 0;
}
.prominent {
    font-family: "PredigeRoundedBold", sans-serif;
    font-size: 36px;
    line-height: 1.2em;
    text-transform: uppercase;
    margin-bottom: 32px;
}

button, a.btn, a.pseudo-btn {
    font-family: "PredigeRoundedBold", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 18px;
    line-height: 1.1em;
    color: var(--blueTxt);
    background: var(--midBlue);
    border: none;
    padding: 18px 44px;
    border: 2px solid var(--midBlue);
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    transition: 0.5s;
}
button:hover, a.btn:hover, a.pseudo-btn:hover {
    color: var(--midBlue);
    background: var(--darkBlue);
}

form.inline-email input[type=email] {
    border: none;
    background: var(--bgWhite);
    font-size: 18px;
    font-style: italic;
    color: var(--blueTxt);
    font-family: "Raleway", sans-serif;
    padding: 19px 26px;
    width: 40%;
}

form.inline-email input[type=email]:focus, button:focus, a.btn:focus, a.pseudo-btn:focus  {
    outline: none;
    box-shadow: 0 0 8px var(--midBlue);
}

form.inline-email .flash-msg {
    margin: 8px 42px 0 42px;
}
.val-error {
    color: var(--red);
    font-size: 14px;
    margin: 2px 0 0 0;
}

ul.dots {
    list-style: none;
    margin: 0;
}
ul.dots li {
    display: inline-block;
    margin: 0 8px 0 0;
}
ul.dots li button {
    width: 12px;
    height: 12px;
    padding: 0;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    transition: 0.5s;
    background: var(--whiteTxt);
    opacity: 0.4;
}
ul.dots li.current button {
    opacity: 1;
}

blockquote {
    font-size: 18px;
    font-style: italic;
    color: var(--blueTxt);
    padding-left: 32px;
    margin: 18px 0 18px 22px;
    border-left: 3px solid var(--fadedBlueBorder);
}
blockquote p {
    margin-bottom: 2px;
}

.mtop { margin-top: 22px; }
.mtop-96 { margin-top: 96px; }
.mbtm-96 { margin-bottom: 96px; }
.mbtm { margin-bottom: 32px !important; }
.teeny-mbtm { margin-bottom: 4px !important; }

.clear-both { clear: both; }

a.back-lnk {
    color: var(--brightBlue);
    text-decoration: none;
    font-size: 14px;
    margin-top: 0;
    border-bottom: 1px solid var(--brightBlue);
    padding-bottom: 1px;
    line-height: 16px;
    transition: 0.3s;
}
a.back-lnk:hover {
    border-color: transparent;
}

/* Header / slideshow areas */
.leader {
    background: var(--darkBlue);
    color: var(--whiteTxt);
    padding: 0;
}
.leader header {
    padding: 24px 32px;
    position: relative;
    overflow: visible;
}
.leader header .logo {
    position: absolute;
    top: 24px;
    left: 32px;
}
.leader header .logo a {
    text-decoration: none;
}
.leader header .logo img {
    width: 130px;
    height: 128px;
}

.leader header nav.main {
    text-align: center;
    margin: 32px 0;
    min-height: 55px;
}
.leader header nav.main > ul {
    list-style: none;
}
.leader header nav.main > ul > li {
    display: inline-block;
    position: relative;
}
.leader header nav.main>ul>li > ul {
    display: block;
    width: 180px;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -90px;
    z-index: 998;
    text-align: center;
    list-style: none;
    opacity: 0;
    height: 0;
    transition: 0.5s;
    padding-top: 4px;
}
.leader header nav.main > ul > li:hover > ul {
    opacity: 1;
    height: auto;
}
.leader header nav.main ul li a,
.donate .mobile-menu nav.main ul li a {
    color: var(--whiteTxt);
    text-decoration: none;
    padding: 14px 32px;
    display: block;
    font-family: "PredigeRoundedBold", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    transition: 0.25s;
}
.leader header nav.main ul li.has-children > a, .donate .mobile-header nav.main ul li.has-children > a {
    background: url('../img/lw/chevron.png') center bottom 0 / 12px auto no-repeat;
}
.leader header nav.main ul li a:hover {
    opacity: 0.75;
}
.leader header nav.main>ul>li>ul>li a,
.donate .mobile-menu nav.main > ul > li > ul > li > a{
    padding: 8px 32px;
    background: var(--darkBlueTrans);
}

.leader .slideshow {
    position: relative;
    height: 687px;
    margin-top: 42px;
}
.leader.med .slideshow {
    height: 556px;
}
.leader.short .slideshow {
    height: 141px;
}
.leader .slideshow .description {
    margin-top: 110px;
    position: relative;
}
.leader .slideshow .description .desc-content {
    position: absolute;
    top: 0;
    left: -200%;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    transition: 0.6s;
}
.leader .slideshow .description .desc-content.active {
    left: 0;
}
.leader.story .slideshow .description {
    margin-top: 125px;
}
.leader.med .slideshow .description {
    margin-top: 145px;
}
.leader.short .slideshow .description {
    margin-top: 5px;
}
.leader.story .slideshow .description h2 {
    color: var(--midBlue);
    font-size: 26px;
    margin-bottom: 10px;
}
.leader.story .slideshow .description h1 {
    margin-bottom: 18px;
}
.leader .slideshow .description h2 {
    font-size: 44px;
    margin-bottom: 18px;
}
.leader .slideshow .description p {
    color: var(--fadedBlueTxt);
    margin-bottom: 22px;
}
.leader .slideshow ul.dots {
    margin-top: 340px;
}
.leader .slideshow .imgs {
    width: 820px;
    height: 580px;
    overflow: hidden;
    margin: 0 0 72px auto;
    z-index: 2;
    position: absolute;
    right: 32px;
}
.leader .slideshow .imgs .ss-img {
    width: 102%;
    height: 102%;
    background-size: cover;
    background-position: center center;
    position: absolute;
    top: -1%;
    left: -1%;
    opacity: 0;
    transition: 0.8s;
}
.leader .slideshow .imgs .ss-img.active {
    opacity: 1;
}
.leader .slideshow .imgs .ss-img img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.leader.med .slideshow .imgs {
    height: 430px;
}
.leader h1 {
    font-size: 64px;
}

/* Tiles */
ul.tiles {
    list-style: none;
}
ul.tiles.down {
    position: absolute;
    top: 0;
    right: 0;
    width: 224px;
    height: 100%;
    z-index: 0;
    margin: 0;
}
ul.tiles.row {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 12.5vw;
    z-index: 0;
    margin: 0;
}
ul.tiles.down.left {
    left: 0;
    right: auto;
}
ul.tiles.down li {
    width: 224px;
    height: 224px;
    background-size: cover;
}
ul.tiles.med {
    width: 175px;
    z-index: -1;
}
.leader ul.tiles.med {
    z-index: 0;
}
ul.tiles.down.xbig {
    width: 255px;
    height: 510px;
}
ul.tiles.down.xbig li {
    width: 255px;
    height: 255px;
}
ul.tiles.med li {
    width: 175px;
    height: 175px;
}
ul.tiles.row li {
    width: 25%;
    height: 100%;
    background-size: cover;
    display: inline-block;
}

/* Footer */
footer {
    background: var(--darkBlue);
    color: var(--whiteTxt);
    padding: 65px 0 32px 0;
}
footer .logo {
    float: right;
}
footer .partner-logo {
    width: 56px;
    margin-top: 32px;
    float: right;
    clear: right;
}
footer nav.footer {
    float: left;
    width: 25%;
    margin-right: 5%;
}
footer nav.footer h3 {
    font-size: 26px;
    color: var(--midBlue);
    margin-bottom: 18px;
}
footer nav.footer ul {
    list-style: none;
}
footer nav.footer ul li a {
    color: var(--whiteTxt);
    text-decoration: none;
    display: block;
    padding: 5px 0;
    transition: 0.5s;
}
footer nav.footer ul li a:hover {
    opacity: 0.5;
}

footer .smallprint {
    font-size: 14px;
    padding: 32px 0 0 0;
    border-top: 1px solid var(--fadedBlueBorder);
    margin-top: 85px;
}
footer .smallprint p {
    display: inline-block;
    margin-right: 32px;
    margin-bottom: 0;
}
footer .smallprint ul.social {
   list-style: none;
   text-align: right;
}
footer .smallprint ul.social li {
    display: inline-block;
    margin-left: 24px;
}
footer .smallprint ul.social li img {
    max-width: 25px;
    max-height: 25px;
}

/* Blocks */
.downloads {
    background: var(--fadedBlueBg);
    color: var(--blueTxt);
    padding: 22px 32px;
    text-align: center;
    text-align: left;
    margin: 42px 0;
}
.downloads h4 {
    font-size: 20px;
    margin-bottom: 18px;
    color: var(--blueTxt);
    padding-bottom: 4px;
    border-bottom: 1px solid var(--fadedBlueBorder);
}
.downloads > ul {
    list-style: none;
    margin: 12px 0;
    padding: 0;
}
.downloads > ul > li {
    width: 50%;
    display: inline-block;
    padding: 0 0 0 32px;
    margin-bottom: 22px;
    box-sizing: border-box;
    background: url("../img/lw/file-icon.svg") left center / auto 24px no-repeat;
}
.downloads > ul > li > a, .right-img .written-content .downloads > ul > li > a {
    color: var(--blueTxt);
    text-decoration: none;
}
.downloads > ul > li button.remove-file {
    margin: 0;
    padding: 6px 15px;
    background: var(--red);
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-family: 'Raleway', sans-serif;
    text-transform: none;
    margin-left: 18px;
    color: var(--whiteTxt);
}
.video {
    padding: 96px 0;
}
.info { 
    position: relative; 
    min-height: 700px;
    padding: 82px 0 72px 0;
    max-height: 700px;
    overflow: hidden;
    box-sizing: border-box;
}
.info .content {
    padding-left: 220px;
    box-sizing: border-box;
}
.info .content .half {
    width: 52.5%;
    float: left;
    margin-right: 5%;
}
.info .content .second-half {
    margin-right: 3%;
    width: 39%;
}

.instagram {
    background: var(--darkBlue);
    color: var(--whiteTxt);
    padding: 72px 0;
    text-align: center;
}
.instagram h3 {
    font-size: 32px;
    margin-bottom: 42px;
    padding-left: 54px;
    background: url('../img/lw/instagram-blue.png') left center / 36px auto no-repeat;
    display: inline-block;
}
.instagram p {
    color: var(--fadedWhiteTxt);
    font-size: 16px;
    margin-bottom: 0;
}
.instagram p a {
    color: var(--fadedWhiteTxt);
}
.instagram p a:hover {
    text-decoration: none;
}

.donate-advert {
    background: var(--darkBlue);
    color: var(--whiteTxt);
    padding: 40px 56px;
    display: inline-block;
    box-sizing: border-box;
}
.info .donate-advert {
    width: auto;
}
.donate-advert h3 {
    font-size: 36px;
    margin-bottom: 32px;
}
.donate-advert .option {
    overflow: auto;
    margin-bottom: 32px;
    position: relative;
}
.donate-advert .option > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    font-size: 0;
}
.donate-advert .option > span {
    display: block;
    float: left;
    width: 88px;
    height: 88px;
    padding: 22px 8px;
    border: 2px solid var(--whiteTxt);
    box-sizing: border-box;
    margin-right: 22px;
    text-align: center;
    font-size: 12px;
}
.donate-advert .option:hover > span {
    border-color: var(--midBlue);
    color: var(--midBlue);
}
.donate-advert .option > span > span {
    font-size: 38px;
    font-family: "PredigeRoundedBold", sans-serif;
    display: block;
}
.donate-advert .option > p {
    margin-top: 16px;
}

.half-responses .response {
    background: var(--darkBlue);
    margin-top: 96px;
    margin-bottom: 96px;
    color: var(--whiteTxt);
    text-align: center;
    padding: 0 0 56px 0;
}
.plain + .half-responses .response {
    margin-top: 16px;
}
.half-responses .response .head-img {
    height: 320px;
    width: 100%;
    margin-bottom: 42px;
    background-size: cover;
    background-position: center center;
}
.half-responses .response.width-4 .head-img {
    height: 284px;
}
.half-responses .response h3 {
    font-size: 38px;
    line-height: 1.1em;
    margin: 0 22px 18px 22px;
}
.half-responses .response.width-4 h3 {
    font-size: 36px;
    margin-bottom: 14px;
}
.half-responses .response p {
    margin-bottom: 22px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.plain {
    margin: 72px 0;
}
.plain .content ul, .written-content ul {
    margin-left: 32px;
    margin-bottom: 18px;
}
.plain .content ul li {
    margin-bottom: 8px;
}
.plain .prominent { font-size: 32px; }
.plain img {
    max-width: 40%;
    margin: 0 32px 22px 0;
    float: left;
}
.plain h2 { margin-bottom: 18px; }

.social-bar, .plain-txt-blue {
    background: var(--brightBlue);
    color: var(--whiteTxt);
    padding: 42px 0;
    text-align: center;
    overflow-y: auto;
}
.social-bar h3, .plain-txt-blue h3 {
    font-size: 42px; 
    display: inline-block;
    margin-right: 56px;
    height: 55px;
    padding: 0;
}
.plain-txt-blue h3, .plain-txt-blue h3 input {
    text-align: center;
    display: block;
    color: var(--whiteTxt);
    margin-bottom: 12px;
}
.plain-txt-blue p {
    text-align: center;
    font-size: 22px;
}
.social-bar ul.social-icons {
    display: inline-block;
    list-style: none;
    padding-top: 0px;
}
.social-bar ul.social-icons li {
    display: inline-block;
    margin-right: 12px;
    padding-top: 10px;
}
.social-bar ul.social-icons li a {
    background: var(--darkBlue);
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 10px;
    display: block;
    position: relative;
}
.social-bar ul.social-icons li a img {
    max-height: 26px;
    max-width: 26px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -13px;
    margin-left: -13px;
}
.social-bar ul.social-icons li a img.fb {
    margin-left: -8px;
}

.left-img, .right-img {
    height: 720px;
    overflow: hidden;
    position: relative;
    padding-top: 48px;
    box-sizing: border-box;
}
.left-img.video,
.right-img.video {
    height: 37.5vw;
}
.right-img {
    background: var(--darkBlue);
    color: var(--whiteTxt);
    padding: 0;
}
.right-img .written-content blockquote {
    color: var(--fadedWhiteTxt);
    border-left-color: var(--whiteBorderFaded); 
}
.right-img .written-content {
    padding: 0 22px;
    text-align: center;
}
.left-img .half-side, .right-img .half-side {
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: #eee;
}
.right-img .half-side {
    left: auto;
    right: 0;
}
.right-img .written-content a {
    color: var(--whiteTxt);
}

.left-img .half-side iframe, .right-img .half-side iframe {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
}
.left-img .img-left-hang {
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    position: absolute;
}
.right-img .img-right-hang {
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
    background-size: cover;
    background-position: center center;
    position: absolute;
}
.left-img .img-left-hang p, .right-img .img-right-hang p {
    font-size: 0;
}
.right-img .written-content { 
    padding: 52px 72px 0 0; 
    box-sizing: border-box;
}
.left-img .written-content {
    padding: 0 0 0 24px;
    box-sizing: border-box;
}

.side-sml {
    margin-bottom: 72px;
    overflow: auto;
}    
.side-sml.rght-side {
    margin-bottom: 0;
}
.side-sml .sxn-img, .side-sml .edit-img {
    width: 25%;
    height: 175px;
    float: left;
    background: #eee;
    margin-right: 3%;
    background-position: center center;
    background-size: cover;
}
.side-sml.rght-side .sxn-img,
.side-sml.rght-side .edit-img {
    float: right;
    margin-left: 3%;
    margin-right: 0;
}
.side-sml .edit-img {
    padding-top: 66px;
    text-align: center;
    box-sizing: border-box;
}
.side-sml .side-content {
    display: inline-block;
    width: 72%;
}
.side-sml h3, .side-sml h3 input {
    color: var(--blueTxt);
    font-size: 24px;
    margin-bottom: 8px;
}

/* Widgets */
.slider-container {
    width: 100%;
    height: 170px;
    overflow: hidden;
    margin-bottom: 42px;
}
.slider-container > button {
    background-color: none;
    border: none;
    background: url('./../img/lw/slider-handle.png') center center / auto 16px no-repeat;
    padding: 12px 1%;
    width: 10px;
    height: 16px;
    margin-top: 64px;
}
.slider-container>button:focus {
    box-shadow: none;
}
.slider-container>button.prev { float: left; }
.slider-container>button.nxt {
    float: right;
    transform: rotate(180deg);
}
.slider-container>button.prev.off, .slider-container>button.nxt.off { opacity: 0; }
.slider-container > div {
    width: 90%;
    margin: 0 auto;
    overflow-x: hidden;
    height: 178px;
    position: relative;
}
ul.slider {
    list-style: none;
    width: 5000%;
    text-align: left;
    position: absolute;
    top:0;
    left: 0;
    transition: 0.5s;
    overflow-y: hidden;
}
#sliderWrap { overflow-y: hidden; }
ul.slider li {
    display: inline-block;
    width: 170px;
    height: 170px;
    margin: 0 12px;
}
ul.slider li a {
    width: 100%;
    height: 170px;
    border: none;
    background-position: center center;
    background-size: cover;
    display: block;
    font-size: 0;
}
ul.slider li button:focus {
    box-shadow: none;
}

/* Forms */
.input {
    margin-bottom: 22px;
}
.input.half {
    width: 47.5%;
    margin-right: 2.5%;
    float: left;
}
.input:nth-child(odd) {
    clear: left;
}
.input label {
    color: var(--blueTxt);
    opacity: 0.6;
    font-size: 14px;
    display: block;
    margin-bottom: 8px;
    line-height: 16px;
}
.input input, .textarea textarea {
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    color: var(--blueTxt);
    padding: 12px 22px;
    display: block;
    width: 97.5%;
    box-sizing: border-box;
    border-radius: 2px;
    border: 1px solid var(--lghtBorder);
}
.input.half input {
    width: 100%;
}
.input input:focus,
form .textarea textarea:focus {
    outline: none;
    border-color: var(--fadedBlueBorder);
}
form .textarea textarea {
    min-height: 225px;
    margin-bottom: 32px;
}
form.contact .input.checkbox {
    margin-bottom: 32px;
}
form.contact .input input[type="checkbox"] {
    float: left;
    width: 20px;
    margin-right: 12px;
}
.val-msg {
    color: var(--red);
    font-size: 14px;
    margin-top: 4px;
    display: block;
}

/* Donation pages */
.donate {
    overflow: hidden;
}
.donate .half {
    width: 50%;
    height: 100vh;
    float: left;
    position: relative;
}

.donate .half.title {
    background: var(--darkBlue);
    padding: 42px 80px;
    box-sizing: border-box;
    position: relative;
    color: var(--whiteTxt);
}

.donate .half.title img {
    max-width: 140px;
}

.donate .half.title h1 {
    margin-top: 18vh;
    font-size: 64px;
    line-height: 1.2em;
}

.donate .half.title h4 {
    margin-top: 5px;
    font-size: 28px;
    color: var(--white);
    opacity: 0.6;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    line-height: 1.4em;
    font-size: 34px;
}
.donate .half.title h4 span span.major {
    font-weight: 700;
}

.donate .half .form {
    margin: 10vh auto;
    width: 80%;
    max-width: 520px;
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.donate .half .form ul.tab-btns {
    list-style: none;
    margin: 0 0 42px 0;
    padding-left: 0;
}

.donate .half .form ul.tab-btns li {
    display: inline-block;
    margin-right: 15px;
}

.donate button:focus {
    box-shadow: none;
}

.donate .half .form ul.tab-btns li button {
    border: 1px solid var(--midBlue);
    border-radius: 0;
    background: var(--white);
    color: var(--blueTxt);
    padding: 14px 22px;
    font-size: 18px;
}

.donate .half .form ul.tab-btns li button.current {
    background: var(--midBlue);
    color: var(--white);
}

.donate ul.amount-options {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

.donate ul.amount-options li {
    margin: 0 0 32px 0;
    clear: left;
}

.donate .amount-options button {
    border: none;
    background: none;
    color: var(--blueTxt);
    padding: 0;
}
.donate .amount-options button .amount {
    display: block;
    float: left;
    width: 90px;
    height: 90px;
    border: 1px solid var(--midBlue);
    padding: 34px 12px;
    box-sizing: border-box;
    font-size: 38px;
    margin: 0 26px 0 0;
}
.donate .amount-options button.monthly .amount {
    padding: 29px 12px;
    font-size: 32px;
}
.donate .amount-options button.monthly .amount span {
    font-family: 'Raleway', sans-serif;
    font-size: 10px;
    display: block;
    text-transform: none;
}

.donate .amount-options li.current button .amount {
    background: var(--midBlue);
    color: var(--white);
}

.donate .amount-options li button .desc {
    color: var(--blueTxt);
    font-family: 'Raleway', sans-serif;
    letter-spacing: 0;
    text-transform: none;
    font-size: 16px;
    padding: 22px 0;
    max-width: 65%;
    display: block;
    text-align: left;
    float: left;
}

.donate .amount-options li.current button .desc {
    color: var(--blueTxt);
}

.donate .input {
    display: block;
    margin: 12px 0 18px 0;
}

.donate .amount-options li .input {
    margin: 12px 0 32px 0;
}

.donate .input label {
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
}

.donate .input input {
    padding: 11px 22px;
    box-sizing: border-box;
    margin-right: 15px;
    font-family: 'Raleway', sans-serif;
    position: relative;
    color: var(--blueTxt);
    width: 100%;
    display: inline-block;
}

.donate .amount-options li .input input {
    font-weight: 600;
    width: 50%;
    padding: 11px 22px 13px 38px;
    font-size: 18px;
    background: url('./../img/core/pound.png') left 12px center / 14px auto no-repeat;
}

.donate .input input:focus,
.donate .amount-options li.current .input input {
    border: 1px solid var(--fadedBlueBorder);
    outline: none;
}

.donate .input.checkbox {
    margin: 24px 0 32px 0;
    overflow: auto;
}

.donate .input.checkbox input {
    float: left;
    width: auto;
    margin-top: 5px;
}

.donate .confirm-pane {
    background: var(--lghtRedBg);
    padding: 12px 22px;
    margin-bottom: 32px;
}

.donate .confirm-pane h3 {
    float: left;
    margin-right: 22px;
}

.donate .confirm-pane p {
    margin: 9px 0;
    font-size: 14px;
}

.pay-btn,
.donate button#submit {
    border: 2px solid var(--midBlue);
    background: var(--midBlue);
    margin-top: 18px;
    padding: 14px 22px;
    font-size: 18px;
    margin-right: 15px;
}

.donate button#submit {
    margin-top: 32px;
}

.pay-btn:hover,
.donate button#submit:hover {
    background: var(--white);
    color: var(--midBlue);
}

.content.narrow {
    max-width: 980px;
    margin: 72px auto;
}
.content.narrow h2, .content.narrow h2 input {
    margin-bottom: 42px;
    color: var(--blueTxt);
}
.content.narrow .a-third {
    width: 26%;
    float: left;
    margin: 0 3.5%;
}
.content.narrow .a-third .sxn-img, .content.narrow .a-third .edit-img {
    width: 100%;
    height: 180px;
    background-size: cover;
    background-position: center center;
    margin-bottom: 18px;
    text-align: center;
}
.content.narrow .a-third .edit-img button {
    margin: 70px auto;
}
.content.narrow .a-third h3, .content.narrow .a-third h3 input {
    font-size: 20px;
    color: var(--blueTxt);
    margin-bottom: 12px;
}

/* Preloader */
.loader-wrap {
    display: block;
    width: 48px;
    margin: 72px auto;
}
.loader {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
}

.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border: 4px solid var(--midBlue);
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 1s ease-in-out infinite;
}

.loader::after {
    border-color: var(--blueTxt);
    animation-delay: 0.5s;
}

@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

/* Error page */
.err-page {
    padding: 72px 0;
}


/* Utility styles for responsiveness */
.mobile, .mobile-menu, .leader header nav.main > ul > li.mob-display, .mobile-header { display: none; }

/* Slip into the responsive styling */
@media only screen and (max-height: 840px) {
    .donate .half.title h1 { margin-top: 14vh; font-size: 52px; }
    .donate .half.title h4 { font-size: 28px; }
}
@media only screen and (max-height: 690px) {
    .donate .half.title h1 { margin-top: 10vh; }
    .donate .half.title h4 { font-size: 24px; }
}

@media only screen and (max-width: 1370px) {
    .info .content .second-half h3 { font-size: 28px; }

    .left-img.video, .right-img.video { height: 45vw; }
    .right-img .written-content blockquote, .left-img .written-content blockquote { font-size: 16px; }
}

@media only screen and (max-width: 1320px) {
    .info .content .second-half.donate-advert .option p {
        font-size: 14px;
    }
}

@media only screen and (max-width: 1300px) {

    .leader.with-slides .slideshow .imgs,
    .leader.story .slideshow .imgs {
        width: 720px;
        height: 520px;
    }
    .leader.story .slideshow, .leader.with-slides .slideshow { height: 607px; }
    .leader.with-slides .slideshow .description { margin-top: 80px; }
    .leader.story ul.tiles.big, .leader.with-slides ul.tiles.big { width: 200px; }
    .leader.story ul.tiles.big li, .leader.with-slides ul.tiles.big li { width: 204px; height: 204px; }

    .leader.med .slideshow .imgs {
        width: 720px;
        height: 420px;
    }
    .leader.med .slideshow { height: 511px; }
    .leader.med ul.tiles.xbig { width: 240px; }
    .leader.med ul.tiles.xbig li { width: 240px; height: 240px; }

}
@media only screen and (max-width: 1270px) {

    .donate-advert h3 { font-size: 28px; }
    .donate-advert .option { margin-bottom: 15px; }
    .donate-advert .option > p { margin-top: 2px; }

}

@media only screen and (max-width: 1200px) {

    .left-img, .right-img { padding-top: 42px; }
    .left-img .written-content { padding-left: 42px; }
    .right-img .written-content { padding-right: 42px; padding-top: 22px; }

    /* Thirds response */
    .half-responses .donate-advert {
        width: 95%;
        float: none;
        margin-bottom: 32px;
        text-align: center;
    }
    .half-responses .donate-advert .option {
        width: 30%;
        margin: 0 1.5% 12px 1.5%;
        display: inline-block;
        text-align: left;
    }
    .half-responses.three .response { 
        margin-top: 0; 
        width: 46.25%;
    }

}

@media only screen and (max-width: 1180px) {
    .info { padding: 60px 0; height: auto; max-height: 999999px; }
    .info .content .half {
        width: 95%;
        margin-top: 0;
    }
    .info .content .second-half {
        width: 95%;
        margin-top: 42px;
        text-align: center;
    }
    .info .content .second-half div .option {
        display: inline-block;
        width: 32%;
        margin: 0 2% 0 0;
        text-align: left;
    }
    .info .content .second-half div .option:last-child { margin-right: 0; }
    .info .content .second-half .option p { font-size: 14px; }

    .left-img.video, .right-img.video { height: 50vw; }

}

@media only screen and (max-width: 1160px) {

    .leader header nav.main ul li a {
        padding-left: 18px;
        padding-right: 18px;
        font-size: 15px;
    }

    .plain .width-8 { width: 50%; }
    .plain .width-4 { width: 45%; }

    .leader.with-slides .slideshow .imgs,
    .leader.story .slideshow .imgs {
        width: 600px;
        height: 420px;
    }
    .leader.story .slideshow .description { margin-top: 96px; }
    .leader.with-slides .slideshow .description { margin-top: 26px; }
    .leader.story .slideshow .description h1 { font-size: 48px; }
    .leader.story .slideshow .description h2 { font-size: 24px; }
    .leader.with-slides .slideshow .description h2 { font-size: 34px; }

    .leader.story .slideshow, .leader.with-slides .slideshow { height: 507px; }
    .leader.story ul.tiles.big, .leader.with-slides ul.tiles.big { width: 179px; }
    .leader.story ul.tiles.big li, .leader.with-slides ul.tiles.big li { width: 179px; height: 179px; }

    .leader.med .slideshow .imgs {
        width: 600px;
        height: 360px;
    }
    .leader.med .slideshow { height: 436px; }
    .leader.med ul.tiles.xbig { width: 215px; }
    .leader.med ul.tiles.xbig li { width: 215px; height: 215px; }

    .donate .half.title.red { width: 40%; }
    .donate .half.title.red h1 { margin-top: 15vh; }
    .donate .half.title.red h4 { font-size: 28px; }
    .donate .half.title.red ul.tiles.row { height: 10vw; }
    .donate .half.form { width: 60%; }

}
@media only screen and (max-width: 1120px) {
    .right-img .written-content blockquote,
    .left-img .written-content blockquote {
        display: none;
    }
}
@media only screen and (max-width: 1080px) {
    .half-responses .donate-advert .option p { font-size: 14px; }
}

@media only screen and (max-width: 1020px) {

    .leader header nav.main ul li:first-child {
        display: none;
    }
    .leader header nav.main ul li a,
    .donate header.mobile-header nav.main ul li a {
        padding-left: 12px;
        padding-right: 12px;
        font-size: 15px;
    }

    .leader ul.tiles {
        width: 150px;
    }
    .leader ul.tiles.down li {
        width: 150px;
        height: 150px;
    }

    .leader.short .slideshow { height: 101px; margin-top: 32px; }
    .leader h1 { font-size: 48px; }

    .leader.with-slides .slideshow .imgs, 
    .leader.story .slideshow .imgs {
        width: 480px;
        height: 340px;
    }
    .leader.story .slideshow, .leader.with-slides .slideshow { height: 391px; }
    .leader.story .slideshow .description { margin-top: 42px; }
    .leader.with-slides .slideshow .description { margin-top: 0; }
    .leader.with-slides .slideshow .description h2 { font-size: 30px; }

    .leader.story ul.tiles.big, .leader.with-slides ul.tiles.big { width: 150px; }
    .leader.story ul.tiles.big li, .leader.with-slides ul.tiles.big li { width: 150px; height: 150px; }
    .leader .slideshow ul.dots { margin-top: 315px; }

    .left-img .img-left-hang, .right-img .img-right-hang, .left-img .half-side, .right-img .half-side { width: 40%; }
    .left-img .written-content.width-6, .right-img .written-content.width-6 { width: 60%; }

    /* Footer */
    footer nav.footer { width: 32%; margin-right: 1%; }

}

@media only screen and (max-width: 980px) {

    .leader.med .slideshow { height: 331px; }
    .leader.med .slideshow .imgs {
        width: 480px;
        height: 260px;
    }
    .leader.med .slideshow .description { margin-top: 80px; }
    .leader.med ul.tiles.xbig { width: 180px; }
    .leader.med ul.tiles.xbig li { width: 180px; height: 180px; }

    .donate .half.title.red { width: 35%; padding: 32px 42px; }
    .donate .half.title.red ul.tiles.row { height: 8.75vw; }
    .donate .half.title.red h1 { margin-top: 12vh; font-size: 44px; }
    .donate .half.title.red h4 { font-size: 20px; }
    .donate .half.form { width: 65%; }
    .donate .half.form .form { width: 90%; }

}

@media only screen and (max-width: 900px) {
    .half-responses .donate-advert .option { display: block; width: 100%; margin-left: 0; margin-right: 0; }
    .half-responses .donate-advert .option p { font-size: 16px; }

}

@media only screen and (max-width: 800px) {

    /* Header / mobile menu */
    .mobile, .mobile-header {
        display: block;
    }

    .leader header {
        width: 100%;
        height: 108px;
        top: 0;
        left: 0;
        padding: 0;
        position: fixed;
        z-index: 3;
        box-sizing: border-box;
        background: var(--darkBlue);
    }
    .leader header.mobile-open {
        height: 100%;
        position: fixed;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .leader header .logo {
        position: absolute;
        top: 18px;
        left: 48px;
    }
    .leader header .logo img {
        width: 110px;
        height: 108px;
    }
    .leader header nav.main ul, .donate header.mobile-header nav.main ul {
        display: none;
    }
    .leader header.mobile-open nav.main ul,
    .donate header.mobile-header.mobile-open nav.main ul {
        display: block;
        margin-top: 182px;
        margin-bottom: 32px;
        text-align: left;
        font-size: 22px;
    }
    .donate header.mobile-header.mobile-open nav.main ul li a {
        color: var(--whiteTxt);
        text-decoration: none;
        padding: 14px 32px;
        display: block;
        font-family: "PredigeRoundedBold", sans-serif;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        transition: 0.25s;
    }
    .leader header.mobile-open nav.main ul li,
    .donate header.mobile-header.mobile-open nav.main ul li {
        display: block;
        margin-bottom: 2px;
    }
    .leader header.mobile-open nav.main ul li a {
        padding-left: 48px;
        padding-right: 48px;
    }
    .donate header.mobile-header.mobile-open nav.main ul li {
        padding-left: 12px;
        padding-right: 12px;
    }
    .leader header.mobile-open nav.main ul li.has-children a,
    .donate header.mobile-header.mobile-open nav.main ul li.has-children a {
        background-position: right 64px center;
        background-size: 20px auto;
    }
    .leader header.mobile-open nav.main ul li.has-children ul,
    .donate header.mobile-header.mobile-open nav.main ul li.has-children ul {
        position: relative;
        margin-top: 0;
        margin-left: 32px;
        margin-bottom: 0;
        padding: 0;
        width: 100%;
        position: relative;
        left: 0;
        display: none;
    }
    .leader header.mobile-open nav.main ul li.has-children:hover ul,
    .donate header.mobile-header.mobile-open nav.main ul li.has-children:hover ul {
        display: none;
    }
    .leader header nav.main a.donate.mobile {
        display: none;
    }
    .donate header.mobile-header.mobile-open nav.main a.donate.mobile { display: none; }
    .donate header.mobile-header.mobile-open nav.main ul li.has-children ul.mob-open,
    .leader header.mobile-open nav.main ul li.has-children ul.mob-open {
        display: block;
        height: auto;
        opacity: 1;
    }
    .leader header.mobile-open nav.main a.donate.mobile {
        background: var(--midBlue) url('../img/lw/pound.png') right 64px center / 32px auto no-repeat;
        color: var(--whiteTxt);
        font-family: "PredigeRoundedBold", sans-serif;
        font-size: 22px;
        text-decoration: none;
        text-transform: uppercase;
        padding: 22px 48px;
        text-align: left;
        box-sizing: border-box;
        transition: 0.3s;
        display: block;
        margin-top: 42px;
    }
    .leader header.mobile-open nav.main a.donate:hover {
        opacity: 0.5;
    }

    .leader ul.tiles {
        display: none !important;
    }

    button.mobile-menu {
        display: block;
        background: none;
        padding: 9px 0;
        border: none;
        color: var(--whiteTxt);
        position: absolute;
        top: 38px;
        right: 48px;
        transition: 0.3s;
    }
    button.mobile-menu:before {
        content: '';
        display: block;
        width: 100%;
        height: 4px;
        background: var(--whiteTxt);
        margin-bottom: 6px;
        transition: 0.3s;
        position: absolute;
        top: 0;
        left: 0;
    }
    button.mobile-menu:after {
        content: '';
        display: block;
        width: 100%;
        height: 4px;
        background: var(--whiteTxt);
        margin-top: 6px;
        transition: 0.3s;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .open button.mobile-menu {
        color: transparent;
        border: none;
        width: 32px;
        height: 32px;
    }
    .open button.mobile-menu:before {
        height: 2px;
        transform: rotate(45deg);
        top: 18px;
        left: -6px;
    }
    .open button.mobile-menu:after {
        height: 2px;
        transform: rotate(-45deg);
        top: 12px;
        bottom: auto;
        left: -6px;
    }
    button.mobile-menu:hover {
        box-shadow: none;
        opacity: 0.5;
    }
    button.mobile-menu:focus {
        box-shadow: none;
    }

    /* Headings */ 
    h2 { font-size: 32px; }
    h3 { font-size: 28px; }
    
    .leader { padding-top: 112px; }
    .leader h1 { font-size: 36px; padding-top: 5px; }
    .leader.med .slideshow, .leader.story .slideshow, .leader.with-slides .slideshow { margin: 0; padding: 0; width: 100vw; height: auto; }
    .leader.med .slideshow .imgs, .leader.story .slideshow .imgs, .leader.with-slides .slideshow .imgs { margin: 0; width: 100vw; height: 380px; right: 0; }
    .leader.with-slides .slideshow .imgs { height: 450px; }
    .leader .slideshow .description { text-align: center; }
    .leader.with-slides .slideshow .description { margin-top: 480px; margin-bottom: 26px; overflow: auto; }
    .leader.with-slides .slideshow .description ul.dots { margin-top: 198px; }
    .leader.story .slideshow .description h2 { font-size: 20px; margin-bottom: 4px; }
    .leader.story .slideshow .description p { margin-bottom: 0; }
    .leader.med .slideshow .description, .leader.story .slideshow .description { margin-top: 400px; margin-bottom: 42px; }
    .leader.short .slideshow { height: auto; padding-bottom: 42px; }

    /* Blocks on mobile */
    .left-img, .right-img { padding-top: 0; height: auto; }
    .left-img .img-left-hang, .right-img .img-right-hang, .left-img .half-side, .right-img .half-side { width: 100%; position: relative; margin-bottom: 42px; height: 450px; }
    .left-img.video, .right-img.video { height: auto; }
    .left-img .half-side.video, .right-img .half-side.video { height: 280px; }
    .left-img .written-content.width-6, .right-img .written-content.width-6 { width: 95%; height: auto; margin-bottom: 42px; padding: 0; }

    .half-responses .response { margin-top: 42px; margin-bottom: 42px; }
    .half-responses .response + .response { margin-top: 0; }
    .half-responses .response h3 { font-size: 24px; }

    .donate-advert { margin-top: 42px; }
    .donate-advert h3 { font-size: 28px; }
    .donate-advert .option { margin-bottom: 15px; }
    .donate-advert .option > p { margin-top: 2px; }

    .plain { margin-top: 42px; }
    .plain form { margin-bottom: 42px; }
    .plain .width-8, .plain .width-4 { width: 95%; }

    .instagram { padding-top: 42px; padding-bottom: 42px; }

    .half-responses .response.width-4 { width: 95%; }

    .info { padding-bottom: 26vw; }
    .info ul.tiles.down {
        width: 100%;
        bottom: 0;
        top: auto;
        height: 20vw;
    } 
    .info ul.tiles.down li {
        width: 20vw;
        height: 20vw;
        display: inline-block;
    }
    .info .content {
        width: 95%;
        padding-left: 0;
    }
    .info .content .second-half {
        text-align: left;
    }
    .info .content .second-half div .option {
        display: block;
        width: 100%;
        margin: 0 0 22px 0;
    }
    .info .content .second-half div .option:last-child { margin-right: 0; }
    .info .content .second-half .option p { font-size: 14px; }

    /* Donate */
    .donate .half.title.red { display: none; }
    .donate .half.title.red ul.tiles.row, .donate .half.title.red a,
    .donate .half.title.red h1, .donate .half.title.red h4 { display: none; }
    .donate .half.form { width: 100%; height: auto; }
    .donate .half.form .form { width: 90%; height: auto; float: none; }

    /* Footer */
    footer .width-2 { margin-top: 22px; }
    footer .smallprint { margin-top: 42px; }
    footer .smallprint { text-align: center; }
    footer .smallprint ul.social { margin-top: 22px; text-align: center; }

    /* Mobile donate header */
    header.mobile-header {
        background: var(--darkBlue);
        height: 95px;
        box-sizing: border-box;
    }
    header.mobile-header.mobile-open {
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
    }
    header.mobile-header .logo.sml {
        position: absolute;
        top: 22px;
        left: 32px;
        width: 92px;
        height: 92px;
    }
    header.mobile-header .logo.sml img {
        width: 100%;
        height: 100%;
    }
    header.mobile-header .mobile-menu {
        top: 28px;
    }

    .right-img .written-content blockquote,
    .left-img .written-content blockquote {
        display: block;
    }
}

@media only screen and (max-width: 650px) {
    .leader.with-slides .slideshow .description ul.dots { margin-top: 268px; }

    .content.narrow { margin: 32px auto; }
    .side-sml { margin-bottom: 32px; }
    .side-sml .side-content { width: 90%; margin: 18px auto 0 auto; display: block; }
    .side-sml .sxn-img { width: 80%; max-width: 320px; height: 250px; margin: 0 auto !important; display: block; float: none !important; }

    body { font-size: 18px; }
}

@media only screen and (max-width: 580px) {

    .left-img .img-left-hang, .right-img .img-right-hang, .left-img .half-side, .right-img .half-side { height: 320px; }
    .left-img.video, .right-img.video { height: auto; }
    .left-img .half-side.video, .right-img .half-side.video { height: 200px; }

    .plain img { width: 100%; float: none; margin: 0 0 12px 0; max-width: 100%; }

    .plain .input.half { width: 97.5%; }

    /* Footer */
    footer nav.footer { width: 100%; margin-right: 0; margin-bottom: 42px; overflow: auto; }
    footer nav.footer ul li { 
        width: 50%;
        float: left;
    }

    .donate .half.form .form ul.amount-options li { margin-bottom: 15px; }

}

@media only screen and (max-width: 540px) {
    .leader.with-slides .slideshow .description ul.dots { margin-top: 262px; }
    .instagram h3 { padding-left: 0; background-image: none; margin-bottom: 26px; }
    .instagram .slider-container { margin-bottom: 26px; }

    .donate .half.form .form { height: auto; margin: 5vh auto; width: 95%; box-sizing: border-box; }
    .donate .half.form .form .tab-btns li { margin-right: 8px; }
    .donate .half.form .form .tab-btns button { font-size: 16px; padding: 12px 15px; }
}

@media only screen and (max-width: 460px) {

    footer nav.footer ul li { 
        width: 100%;
        float: none;
    }

    .half-responses .response .head-img { height: 250px; }
    .leader.med .slideshow .imgs { height: 280px; }
    .leader.with-slides .slideshow .imgs { height: 320px; }
    .leader.med .slideshow .description { margin-top: 300px; }
    .leader.with-slides .slideshow .description { margin-top: 348px; }

    form.inline-email input[type=email] { width: 80%; float: none; box-sizing: border-box; margin-bottom: 12px; }

}

@media only screen and (max-width: 440px) {
    .donate .half.form .form .tab-btns li { margin-right: 5px; }
    .donate .half.form .form .tab-btns button { font-size: 15px; padding: 10px 12px; }
}

@media only screen and (max-width: 385px) {

    .donate .half.form .form .tab-btns button { padding: 10px 6px; font-size: 13px; }
    .donate .amount-options button .amount { margin-right: 12px; width: 70px; height: 70px; font-size: 26px; padding: 24px 12px; }
    .donate .amount-options button.monthly .amount { font-size: 26px; padding: 19px 8px; }
    .donate .amount-options button.monthly .amount span { font-size: 8px; }
    .donate .amount-options li button .desc { font-size: 14px; padding: 16px 0; }
}